import { Route, Routes} from "react-router-dom";
import Home from "./Pages/Home/Home";
import OurServices from "./Pages/OurServices/OurServices";
import OurStory from "./Pages/OurStory/OurStory";
import "./App.css";

function App() {
  return (
    <div className="App">
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/ourservices" element={<OurServices />} />
            <Route path="/ourstory" element={<OurStory />} />
        </Routes>
    </div>
  );
}

export default App;
