import "./Footer.css";
import {React, useState} from 'react';
import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <div className='footer'>
        <div className='footer__logo'>
            <img src='../images/logo_bijeli.svg' />
            <p>© Obscale d.o.o. All rights reserved. <br /> Cehaje bb, 75350 Srebrenik. BiH</p>
            <div id="mobile-socials" className="footer__socials">
            <a href='#'><img src="../images/instagram.png" /></a>
            <a href='#'><img src="../images/facebook.png" /></a>
            <a href='#'><img src="../images/telegram.png" /></a>
            </div>
        </div>
        <div className='footer__links'>
            <Link id='nav-item' to='/'>Home</Link>
            <Link id='nav-item' to='/ourservices'>Our Services</Link>
            <Link id='nav-item' to='/ourstory'>Our Story</Link>
        </div>
        <div className="footer__contact">
            <span><img src='../images/mail_bijeli.png'/><p>marketing@obscale.com</p></span>
        </div>
        <div className="footer__socials">
            <a href='#'><img src="../images/instagram.png" /></a>
            <a href='#'><img src="../images/facebook.png" /></a>
            <a href='#'><img src="../images/telegram.png" /></a>
        </div>
        <p id="mobile-copyright">© Obscale d.o.o. All rights reserved. <br /> Cehaje bb, 75350 Srebrenik. BiH</p>
    </div>
  )
}
