import React from 'react';
import "./Button.css";

export default function Button(props) {
  return (
    <button style={{width: props.width, height: props.height}}>
        {props.text}
    </button>
  )
}
