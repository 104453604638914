import {React, useState, useEffect, useRef} from 'react';
import Header from '../../Components/Header/Header';
import "./Home.css";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '../../Components/Button/Button';
import Footer from '../../Components/Footer/Footer';

export default function Home() {

  const [value, setValue] = useState();

  const divRef = useRef();

  useEffect(() => {
    if (!divRef.current) return;

    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          divRef.current.classList.add('animate');
        };
      });
    });
    observer.observe(divRef.current);

    return () => {
      try {
        observer.unobserve(divRef.current);
      } catch {};
    };
  }, [divRef.current]);

  return (
    <div className='homePage'>
      <Header />
      <div className='homePage__hero'>
        <img id='line' src='../images/linija.png' />
        <img id='line-mobile' src='../images/linija-mobile.png' />
        <div className='homePage__hero__left'>
          <h1>You have a
            <span style={{ color: '#FC3073', fontFamily: 'Nunito' }}> great</span> product? <br />
            We <span style={{ color: '#FC3073', fontFamily: 'Nunito' }}>cover</span> the rest
          </h1>
          <br />
          <p>
            20% of businesses fail in the first year.
            50% of them don't make it past year 5.
            <br /><br />
            Many fabulous solutions never get the chance to improve people's lives.
          </p>
          <br />
          <p>
            The top reasons?
          </p>
          <br />
          <span id='arrow-list-item'><img src='../images/strelica.png' /><p>Not having an effective business plan</p></span>
          <span id='arrow-list-item'><img src='../images/strelica.png' /><p>Not putting the customers first</p></span>
          <br />
          <p>The connection between these two? <span style={{ color: '#FC3073' }}>Lack of an effective marketing strategy.</span></p>
        </div>
        <div className='homePage__hero__right'>
          <div className='homePage__hero__right__form'>
            <p>Send us mail and find out how you can stand out among the competition</p>
            <input type='text' placeholder='Name' />
            <PhoneInput
              international
              defaultCountry='AE'
              placeholder="Enter phone number"
              value={value}
              onChange={setValue} />
            <Button width='197px' height='62px' text='Contact us' />
          </div>
        </div>
      </div>
      {/*sekcija 2*/}
      <div className='homePage__sectionTwo'>
        <img id='line2' src='../images/linija2.svg' />
        <h1>Obscale Marketing offers a <span style={{color: '#FC3073', fontFamily: 'Nunito'}}>complete marketing solution</span> with an effective 5 step program</h1>
        <div ref={divRef} className='homePage__sectionTwo__box'>

            <div id='item1' className="homePage__sectionTwo__box__item">
              <img src='../images/num1.png' />
              <div>
                <h3>Scale & evaluation </h3>
                <p>We assess work and strategies implemented so far and detect weak spots</p>
              </div>
            </div>

            <div id='item2' className='homePage__sectionTwo__box__item'>
              <img src='../images/num2.png' />
              <div>
                <h3>Recommendations </h3>
                <p>Based on the analysis, we'll create a list of practical steps that will strengthen your brand and help it reach an audience</p>
              </div>
            </div>

            <div id='item3' className='homePage__sectionTwo__box__item'>
              <img src='../images/num3.png' />
              <div>
                <h3>Creating strategy </h3>
                <p>Each company has a perfect marketing mix. We'll help you find yours</p>
              </div>
            </div>
          
            <div id='item4' className='homePage__sectionTwo__box__item'>
              <img src='../images/num4.png' />
              <div>
                <h3>Implementation </h3>
                <p>Our team consists of storytelling experts, a film crew, and digital marketers ready to put plans into effective action</p>
              </div>
            </div>

            <div id='item5' className='homePage__sectionTwo__box__item'>
              <img src='../images/num5.png' />
              <div>
                <h3>Creating strategy </h3>
                <p>Each company has a perfect marketing mix. We'll help you find yours</p>
              </div>
            </div>
          
        </div>
      </div>
      {/*kraj sekcije 2*/ }
      {/*sekcija 3 */}
      <div className='homePage__sectionThree'>
        <img id='line3' src='../images/linija3.svg' />
          <div className='homePage__sectionThree__left'>
              <h1>YOUR <span style={{color: '#FC3073', fontFamily: 'Nunito'}}>story</span></h1>
              <p>
              Entrepreneurs presume great products and services naturally find their way to an audience. 
              <br /><br />
              That means leaving your chances of success to pure luck. 
              <br /><br />
              <span style={{color: '#FC3073', fontFamily: 'Nunito'}}>We are here to tell a different story. YOUR story that reaches its target audience. </span>
              <br /><br />
              All that while you have additional time to improve your products and work on new ones.
              <br /><br />
              </p>
              <Button width='346px' height='62px' text='Get your free evaluation now'/>
          </div>
          <div className='homePage__sectionThree__right'>
              <img src='../images/sekcija3.png' />
          </div>
      </div>
      <Footer />
    </div>
  )
}
