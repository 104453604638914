import React from 'react';
import Header from '../../Components/Header/Header';
import "./OurStory.css";
import Footer from "../../Components/Footer/Footer";

export default function OurStory() {
  return (
    <div className='ourStory'>
      <Header />
      <div className='ourStory__container'>
        <div className='ourStory__container__left'>
          <h1>Our <span style={{ color: '#FC3073', fontFamily: 'Nunito' }}>story</span></h1>
          <p>
            Our digital adventure started 7 years ago with the new prospects offered by digital marketing.
              <br /> <br />
            After mastering Google Ads, we transformed our physical stores business into e-commerce, eventually specializing in natural products.
              <br /> <br />
            A "Jack of all digital trades" philosophy and experience led us to finding our real passion - helping other brands find and execute their perfect digital marketing strategy and connecting them with their target audience.
              <br /> <br />
          </p>
        </div>
        <div className='ourStory__container__right'>
              <img src='../images/Image.png' />
        </div>
      </div>
      <Footer />
    </div>
  )
}
