import{ React, useState} from 'react';
import Header from '../../Components/Header/Header';
import "./OurServices.css";
import Footer from "../../Components/Footer/Footer";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import Button from '../../Components/Button/Button';

export default function OurServices() {

  const [value, setValue] = useState();

  return (
    <div className='ourServices'>
      <Header />
      <img id='line4' src='../images/linija4.svg' />
      <img id='line5' src='../images/linija5.svg' />
      <div className='ourServices__container'>
        <h4>What we are passionate about</h4>
        <h1>Our services</h1>
        <p>We work with a wide array of clients interested in optimizing their digital marketing strategy with three approaches:</p>
        <div className='ourServices__container__box'>

          <div className='ourServices__container__box__item'>
            <img src='../images/laptop.png' />
            <div>
              <h2>Complete solution</h2>
              <p>Clients who want to be fully dedicated to products and services and let us take care of the rest. They hop on brief meetings to catch up with the news and results.</p>
            </div>
          </div>

          <div className='ourServices__container__box__item'>
            <img src='../images/target.png' />
            <div>
              <h2>Target solution</h2>
              <p>Clients who already have a strategy that detects weak points, and want to turns things around with:</p>
              <div className='item-grid'>
                <span id='arrow-list-item'><img src='../images/strelica.png' /><p>new marketing copy</p></span>
                <span id='arrow-list-item'><img src='../images/strelica.png' /><p>engaging videos</p></span>
                <span id='arrow-list-item'><img src='../images/strelica.png' /><p>monetizing traffic on <br /> their websites</p></span>
                <span id='arrow-list-item'><img src='../images/strelica.png' /><p>creating effective targeted <br /> campaigns on the best websites</p></span>
              </div>
            </div>
          </div>

          <div className='ourServices__container__box__item'>
            <img src='../images/specialized.png' />
            <div>
              <h2>Specialized services</h2>
              <p>Our sister companies Scale Pick and Scale Lead specialize in digital marketing niches.

                Scale Pick offers the next level of native ads that merges top-notch technology and expertise into a tailored native ads experience.

                Scale Lead is our premium triple win network service that connects great advertisers, publishers & target audiences and provides a tailored CPA Network Experience.

                All of our services are run by a dedicated account manager that makes sure our services are tailored specifically to your needs.
              </p>
            </div>
          </div>

          <div className='ourServices__container__form'>
            <p>Get a free consultation and find out what's the best strategy for you</p>
              <div className='ourServices__container__form__inputs'>
                <input type='text' placeholder='Name' />
                <PhoneInput
                  international
                  defaultCountry='AE'
                  placeholder="Enter phone number"
                  value={value}
                  onChange={setValue} />
              </div>
              <Button width='197px' height='62px' text='Contact us' />
          </div>

        </div>
      </div>
      <Footer />
    </div>
  )
}
