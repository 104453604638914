import {React, useState} from 'react';
import { Link } from 'react-router-dom';
import "./Header.css";

export default function Header() {

  const [menuOpen, setMenuOpen] = useState(false);

  const menuHandler = (event) => {
    setMenuOpen(m => !m);
  }

  return (
    <div className='header'>
        <div className='header__loog'>
          <img src='../images/logo.svg' />
        </div>
        <div className='header__navbar'>
          <Link id='nav-item' to='/'>Home</Link>
          <Link id='nav-item' to='/ourservices'>Our Services</Link>
          <Link id='nav-item' to='/ourstory'>Our Story</Link>
        </div>
        <div className='header__contact'>
            <span><img src='../images/email.png'/><p>marketing@obscale.com</p></span>
        </div>
        <div className='header__hamburger'>
            <img onClick={()=>menuHandler()} src='../images/hamburger.png' />
        </div>
        <div style={{display: menuOpen ? "flex" : "none"}} className='header__navbar__mobile'>
            <div className='header__navbar__mobile__head'>
              <img src='../images/logo_bijeli.svg' />
              <img onClick={()=>menuHandler()} id='close' src='../images/close.png' />
            </div>
            <Link id='nav-item' to='/'>Home</Link>
            <Link id='nav-item' to='/ourservices'>Our Services</Link>
            <Link id='nav-item' to='/ourstory'>Our Story</Link>
            <span><img src='../images/mail_bijeli.png'/><p>marketing@obscale.com</p></span>
            <div className='header__navbar__mobile__socials'>
              <a href='#'><img src="../images/instagram.png" /></a>
              <a href='#'><img src="../images/facebook.png" /></a>
              <a href='#'><img src="../images/telegram.png" /></a>  
            </div>
        </div>
    </div>
  )
}
